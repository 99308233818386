@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  

@media (max-width: 767px) {
  .custom-botones{
    font-size: 1rem !important;
    padding: 0.75rem 1.5rem !important;
    margin: 1rem 0.5rem 0rem 0rem !important;
  }
}

@media (min-width: 768px) {
  .custom-botones {
    font-size: 3rem !important;
    padding: 0.75rem 1.5rem !important;
    margin: 5rem 3rem 0rem 0rem !important;
  }
}


.important-margin {
  margin: 10rem 0rem 0rem 0rem !important;
  text-align: center;
}

@keyframes slideRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%); /* Ajusta según necesites */
  }
}

.deslizar-derecha {
  animation: slideRight 0.5s forwards !important; /* Ajusta la duración según prefieras */
}

