/* Estilo por defecto */
.custom-font-size {
    font-size: 16px !important; /* Tamaño de fuente estándar */
  }
  
  /* Pantallas pequeñas (menos de 768px) */
  @media (max-width: 767.98px) {
    .custom-font-size {
      font-size: 12px !important; /* Tamaño de fuente más pequeño para pantallas pequeñas */
    }
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  
  .arrow-bounce {
    animation: bounce 2s infinite;
  }
  